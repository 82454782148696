import { createGlobalStyle } from 'styled-components';

import GothamBold from '../../static/fonts/Gotham-Bold.woff';
import GothamBook from '../../static/fonts/Gotham-Book.woff';

const GlobalTAndCStyles = createGlobalStyle`
@font-face {
  font-family: 'Gotham-Bold';
  src: url(${GothamBold}) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-Book';
  src: url(${GothamBook}) format('woff');
  font-weight: normal;
  font-style: normal;
}
    
body {
        margin: 0;
        padding: 0;
        color: #444;
        font-family: 'Gotham-Book', arial, sans-serif;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.02em;
    }

    h1 {	
		margin: 0 0 30px 0;
		padding: 0;		
		font-family: 'Gotham-Bold', arial, sans-serif;
		font-weight: normal !important;
		color: #000;
		font-size: 22px;
		line-height: 30px;
		letter-spacing:1.5px;
        text-align: center;
		}
	h2 {			
		margin: 0 0 30px 0;
		padding: 0;	
		font-family: 'Gotham-Bold', arial, sans-serif;
		font-weight: normal !important;
		font-size: 18px;
		line-height: 24px;
		text-align: center;
		}
			
	hr {
		height: 1px;
		border: none;
		background: #fff;
		margin: 40px 0 20px 0;
		clear: both;
		}
		
    p {
        margin: 0 0 10px 0;
        padding: 0;
    }
    b {
		font-family: 'Gotham-Bold', arial, sans-serif;
		}
		
	a:hover, a:active, a:focus, object { 
		outline: none; 
		}			
	a {
		text-decoration: none;
		color: #11487b;	
		transition: .15s ease-out;
		}
	a:hover {
		text-decoration: none;
		color: #11487b;
		}
`;
export default GlobalTAndCStyles;
