import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { TAndCContainer } from '../../styles';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import GlobalTAndCStyles from '../../styles/globalTAndCStyles';

function TAndCPageTemplate({ location }) {
  const { allContentfulGeneralPageTermsAndConditions } = useStaticQuery(graphql`
    query PageTermsAndConditionsQuery {
      allContentfulGeneralPageTermsAndConditions(
        filter: { type: { type: { eq: "residentialTermsAndConditions" } } }
      ) {
        edges {
          node {
            slug
            title
            type {
              type
            }
            node_locale
            sections {
              ... on ContentfulContent {
                id
                title
                type
                subText
                header
                node_locale
                contentDetails {
                  raw
                }
              }
            }
          }
        }
      }
    }
  `);

  const tAndCDataEn = allContentfulGeneralPageTermsAndConditions?.edges
    .find((edge) => edge.node.node_locale === 'en-US')
    ?.node?.sections?.find((item) => item);

  const tAndCDataFr = allContentfulGeneralPageTermsAndConditions?.edges
    .find((edge) => edge.node.node_locale === 'fr-CA')
    ?.node?.sections?.find((item) => item);

  const frSlug = allContentfulGeneralPageTermsAndConditions?.edges.find(
    (edge) => edge.node.node_locale === 'fr-CA'
  )?.node?.slug;

  return (
    <>
      <GlobalTAndCStyles />
      <TAndCContainer>
        {location.pathname.includes(
          frSlug[frSlug.length - 1] === '/' ? frSlug.slice(0, -1) : frSlug
        )
          ? tAndCDataFr?.contentDetails?.raw &&
            documentToReactComponents(
              JSON.parse(tAndCDataFr?.contentDetails?.raw)
            )
          : tAndCDataEn?.contentDetails?.raw &&
            documentToReactComponents(
              JSON.parse(tAndCDataEn?.contentDetails?.raw)
            )}
      </TAndCContainer>
    </>
  );
}

export default TAndCPageTemplate;
